import Vue from 'vue'
import Router from 'vue-router'

import { jsonp } from 'vue-jsonp'

import {arrayChangeKey} from '@/utils/index.js'
import Home from '../views/home.vue'
import Recruit from "../views/recruit.vue"
import Information from "../views/information.vue"
import MoreInfo from "../views/moreInfo.vue"
// import AboutUs from "../views/aboutUs.vue"
import MineInfo from "../views/mineInfo.vue"
import Message from "../views/message.vue"
import Resume from "../views/resume/index.vue"
import Apply from "../views/apply.vue"
import applyTable from "../views/applyTable.vue"
import Detail from "../views/detail.vue"
import ResumeDetail from "../views/resumeDetail.vue"
import Identification from "../views/identification.vue"
import iframeViews from "../views/iframeViews.vue"
import {ssoLogin ,ssoCheck} from '../api/login.js'
import {
    setToken,
    removeToken
} from '@/utils/auth'

Vue.use(Router)

const routes=[{
    path: '/',
    redirect: '/home'
},
    {
        name: 'home',
        path: '/home',
        // redirect: '/recruit'
        // component: () => import('../views/home')
        component:Home
    },
    {
        name: 'Recruit',
        path: '/recruit',
        // component: () => import('../views/home')
        component:Recruit
    },
    {
        name: 'Information',
        path: '/information',
        // component: () => import('../views/home')
        component:Information
    },
    {
        name: 'MoreInfo',
        path: '/moreInfo',
        // component: () => import('../views/home')
        component:MoreInfo
    },
    // {
    //     name: 'AboutUs',
    //     path: '/aboutUs',
    //     // component: () => import('../views/home')
    //     component:AboutUs
    // },
    {
        name: 'MineInfo',
        path: '/mineInfo',
        // component: () => import('../views/home')
        component:MineInfo,
        // auth:true,
    },
    {
        name: 'Message',
        path: '/message',
        // component: () => import('../views/home')
        component:Message
    },
    {
        name: 'Resume',
        path: '/resume',
        // component: () => import('../views/home')
        component:Resume
    },
    {
        name: 'Apply',
        path: '/apply',
        // component: () => import('../views/home')
        component:Apply
    },
    {
        name: 'Detail',
        path: '/detail',
        // component: () => import('../views/home')
        component:Detail
    },
    {
        name: 'ResumeDetail',
        path: '/resumeDetail',
        // component: () => import('../views/home')
        component:ResumeDetail
    },
    {
        name: 'Identification',
        path: '/identification',
        // component: () => import('../views/home')
        component:Identification
    },


    // {
    //   name: 'mine',
    //   path: '/mine',
    //   auth:true,
    //   // component: () => import('../views/mine'),
    //   component: Mine
    // }

    {
        name: 'applyPost',
        path: '/applyPost',
        component: () => import('../views/applyPost')
    },
    {
        //小程序提交申请
        name: 'appApplyPost',
        path: '/appApplyPost',
        component: () => import('../views/appApplyPost')
    },
    {
        name: 'applyTable',
        path: '/applyTable',
        component: () => import('../views/applyTable')
    },

    {
        name: 'applyTableToreal',
        path: '/applyTableToreal',
        component: () => import('../views/applyTable')
    },
    // {
    //   name: 'applyTableTorealVue',
    //   path: '/applyTableTorealVue',
    //   component: () => import('../views/applyTableToreal')
    // },

    {
        name: 'iframeViews',
        path: '/iframeViews',
        component: () => import('../views/iframeViews')
    },

    {
        name: 'topic',
        path: '/topic',
        component: () => import('../views/topic')
    },
    {
        name: 'result',
        path: '/result',
        component: () => import('../views/result')
    },
    {
        name: 'resultDetail',
        path: '/resultDetail',
        component: () => import('../views/resultDetail')
    },
    // {
    //   name: '404',
    //   path: '/404',
    //   component: () => import('../views/404')
    // },
]
const routesMap =arrayChangeKey(routes ,'name');
const router= new Router({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition && to.meta.keepAlive) {
            return savedPosition;
        }
        return { x: 0, y:0 };
    },
    base:'/',
    routes: routes
})

router.beforeEach(async (to, from, next) => {
    let oldssoToken=window.localStorage.getItem('ssoToken' );
    let ssoRes =await jsonp(process.env.VUE_APP_BASE_SSO+'/prod-api/external/user/jsonp', {
        callbackQuery: 'callback',
        callbackName: 'callback_login',
        r:Math.random()*100000000
    },3000).catch(err=>{
        console.log('jsonp error:',err)
    })
    console.log('sso callback:' ,ssoRes)
    if(ssoRes){
        if(ssoRes.code==200 && ssoRes.token){
            //登录
            window.localStorage.setItem('ssoToken' ,ssoRes.token);
        }else{
            //未登录401
            window.localStorage.removeItem("ssoToken")
            window.localStorage.removeItem("userToken")
        }
    }else{
        //接口请求失败
    }
    const userInfo =  window.localStorage.getItem('userToken');
    const ssoToken = window.localStorage.getItem('ssoToken');
    if(ssoToken){
        // console.log(oldssoToken,'oldssoToken')
        // console.log(ssoToken,'ssoToken')
        // console.log(oldssoToken!=ssoToken,'oldssoToken!=ssoToken')
        //判断旧的ssoToken与新获取的是否相似   存在ssoToken有效 userToken未同步更新的情况  （解决用户从主网站退出登录，由于并无退出登录通知，未清除本网站usertoken，又在其他网站登录，然后手动跳转到本网站；）
        if(!userInfo||oldssoToken!=ssoToken){
            // console.log('oldssoToken!=ssoToken-----')
            //未登录则执行登录逻辑
            ssoLogin(ssoToken).then(res=>{
                if(res.code==200 && res.token){
                    setToken(res.token)
                    next()
                }else{
                    if (routesMap[to.name]&& routesMap[to.name].auth && !userInfo){
                        next({ name: 'home' })
                    } else{
                        next()
                    }
                }
            }).catch(e=>{
                removeToken();
                if (routesMap[to.name]&& routesMap[to.name].auth && !userInfo){
                    next({ name: 'home' })
                } else{
                    next()
                }
            })
        }else{
            //已登录状态，检查sso token是否有效
            ssoCheck(ssoToken).then(res=>{
                if(res.code==200){
                    //sso 有效
                    next()
                }else{
                    //sso无效，退出
                    removeToken();
                    if (routesMap[to.name]&& routesMap[to.name].auth && !userInfo){
                        next({ name: 'home' })
                    } else{
                        next()
                    }
                }
            }).catch(e=>{
                //sso无效，退出
                removeToken();
                if (routesMap[to.name]&& routesMap[to.name].auth && !userInfo){
                    next({ name: 'home' })
                } else{
                    next()
                }
            })

        }

    }else{
        //无ssoToken，则清除登录
        removeToken();
        if (routesMap[to.name]&& routesMap[to.name].auth && !userInfo){
            next({ name: 'home' })
        } else{
            next()
        }
    }



})

export default router
