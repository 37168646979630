
const sm2 = require('sm-crypto').sm2
const privateKey='00e2f8c8dd29901588852c3858a4e55f3a76f538cf5f1791f3dcc49a576b82e464'
const TokenKey = 'userToken'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  localStorage.removeItem('ssoToken');
  return  localStorage.removeItem(TokenKey)
}
export function doDecryptData(data) {
  let str=data
  let decryptData=sm2.doDecrypt(str, privateKey);
  return   JSON.parse(decryptData)
}
