import Vue from 'vue'
import App from './App.vue'
import store from './store'
Vue.prototype.$store=store
// 引入vue-router --------------------
// import VueRouter from 'vue-router'
import router from './router/index.js'
// Vue.use(VueRouter)
// ----------------------------------
import ElementUI from 'element-ui';
import 'animate.css'

import VueLazyload from 'vue-lazyload'
import 'element-ui/lib/theme-chalk/display.css';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/reset.css' // global css
import './assets/css/common.css'

// Import Swiper styles
// import 'swiper/dist/js/swiper'
// import 'swiper/dist/css/swiper.css'

import VFormRender from '../lib/vform/VFormRender.umd.js'
import '../lib/vform/VFormRender.css'


// 图片上传组件
import ImageUpload from "@/components/ImageUpload"
// 单个上传组件
import UplodeOne from "@/components/ImageUpload/uplodeOne"
import uploadFile from "@/components/uploadFile"
import topNav from "@/components/topNav"
import footerBox from "@/components/footerBox"
import empty from "@/components/empty"
import mineMenu from "@/components/mineMenu"
import Pagination from "@/components/Pagination";
import FileUpload from "@/components/FileUpload";
import uoloadHead from "@/components/uoloadHead"

import * as filters from '@/utils/filter.js'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

import init from '@/utils/init.js'
Vue.prototype.$init = init

Vue.component('topNav', topNav)
Vue.component('footerBox', footerBox)
Vue.component('ImageUpload', ImageUpload)
Vue.component('uploadFile', uploadFile)
Vue.component('empty', empty)
Vue.component('mineMenu', mineMenu)
Vue.component('Pagination', Pagination)
Vue.component('uplodeOne', UplodeOne)
Vue.component('FileUpload', FileUpload)
Vue.component('uoloadHead', uoloadHead)

Vue.use(ElementUI);
Vue.use(VFormRender)  //注册了v-form-render等组件

Vue.config.productionTip = false
Vue.config.strict = false // 关闭严格模式


Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: "success" });
}

Vue.prototype.msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: "error" });
}

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
}


Vue.use(VueLazyload, {
  attempt: 1  ,// 下载图片时错误重连次数
  error: 'data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs=',  // 加载错误时
  loading: 'data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs=', // 加载中
})

// 设置根元素font-size
function setRem(){
  // 获取屏幕宽度
  var w = document.documentElement.clientWidth;
  console.log(w,'-----')
  // if()
  if(window.top === window.self) {
    //不在iframe中
    document.documentElement.style.fontSize = w / 10 + 'px';
    console.log("--不在iframe中---------------")
  } else {
    document.documentElement.style.fontSize = w / 5.37 + 'px';
    //在iframe中
    console.log( window.location.hash,' window.location.href')

    console.log('在iframe中')
  }
  if(window.location.hash.indexOf('appApplyPost')>-1){
    document.documentElement.style.fontSize = w / 2.4 + 'px';
  }
}
setRem();
window.addEventListener("resize", setRem);


new Vue({
  render: h => h(App),
  router: router,
  store,
}).$mount('#app')
